.mouse-hover {
    cursor: pointer;
}
.divider-light {
    background-color: #FFFFFF;
    opacity: 1;
}

.not-published {
    color: yellow;
    font-style: italic;
}
.blog-item.not-published-border {
    border: 1px dashed yellow;
}

hr {
    opacity: .2;
}
b, strong {
    font-weight: 600;
}
.btn-mod.btn-large,
.btn-mod.btn-medium {
    font-size: 14px;
    font-weight: 600;
}
.btn-mod, a.btn-mod {
    background-color: transparent;
}

.text-capfirst:first-letter {
    text-transform: uppercase;
}

.bg-dark {
    background-color: var(--color-dark-1) !important;
}
h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-title);
}

img.align-left,
img.align-right {
    margin-bottom: 10px;
    margin-top: 10px;
}
img.align-left {
    float: left;
    margin-right: 20px;
}
img.align-right {
    float: right;
    margin-left: 20px;
}

.mfp-bg {
    opacity: .95;
}

figcaption {
    font-style: italic;
    font-size: .8rem;
}
.light-content .work-img-bg {
    background-color: #090623;
}
.pageHome .logo {
    display: none;
}
.pageHome .body-scrolled .logo {
    display: flex;
}
/* ======================= PAGE STANDARD =========================*/
#standard .container p {
    line-height: 1.5 !important;
}

/* ======================= MAIN NAV =========================*/
.main-nav.dark {
    background-color: transparent;
}
.main-nav:not(.transparent) {
    -webkit-backdrop-filter: blur(0);
     backdrop-filter: blur(0);
}
.main-nav.dark.body-scrolled {
    background-color:rgba(10, 10, 10, .905);;
}

.main-nav.dark .inner-nav ul > li > a:after {
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    margin-left: 8px;
    position: absolute;
    margin-top: 5px;
    width: 0;
    overflow: hidden;
    -webkit-transition: width .1s ease-in-out;
    -moz-transition: width .1s ease-in-out;
    -o-transition: width .1s ease-in-out;
    transition: width .1s ease-in-out;
}
.main-nav.dark .inner-nav ul > li ul.mn-sub li > a:after {
    display: none;
}

.main-nav.dark .inner-nav ul > li > a.selected {
    font-weight: bolder;
    opacity: 1;
}
.main-nav.dark .inner-nav ul > li > a.selected:after,
.main-nav.dark .inner-nav ul > li > a:hover:after {
    width: 100%;
}
.main-nav.dark .inner-nav ul > li .mn-group-title {
    padding: 0;
    font-weight: bold;
}


.mobile-on .desktop-nav {
    display: none;
    left: -20px;
    padding: 0 15px;
    position: absolute;
    top: 100%;
    width: calc(100% + 40px);
    z-index: 100;
    height: 100vh;
    background: hsla(0, 0%, 7%, .995);
}
.mobile-on .desktop-nav ul li a{
    text-align: center;
}
.main-nav.dark .inner-nav ul>li>a:after {
    display: none;
}
/* ======================= FOOTER =========================*/
.footer{
    background: url("/static/core/img/envelope.webp") no-repeat center bottom -175%;
}
.footer .footer__copyright p{
    margin-bottom: 10px;
    color: var(--color-gray-light-3);
}
.footer__adress p {
    line-height: 1.45em;
}
.footer__adress p a {
    text-decoration: none;
}
.footer__adress p a:hover {
    text-decoration: underline!important;
}
/* ======================= CONTENT PAGE HEADER=========================*/
.contentHeader h1 {
    margin-top: 0;
    font-size: 70px;
    font-weight: 500;
    line-height: 1.085;
    letter-spacing: -0.035em;
    opacity: 1;
    transform: scale(1.0);
    margin-bottom: 0!important;

    /* animation */
    animation-name: fadeInUp;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.contentHeader h2 {
    font-size: 15px;
    letter-spacing: 0.039em;
    margin-bottom: 30px!important;
    display: inline-block;
    padding: 0.45em 0.7em;
    font-weight: 500;
    text-transform: uppercase;
    border: 1px solid currentColor;
    border-radius: 70px;

    /* animation */
    animation-name: fadeInUp;
    opacity: 0.001;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.contentHeader p {
    font-size: 23px;
}

/* ======================= CONTACT PAGE =========================*/
.light-content .map-section {
    background: rgba(39, 8, 17, 0.80);
}

.contactPage .contactPage__columnContent {
    color: #fff;
    /*color: var(--color-dark-mode-gray-1);*/
    font-size: 18px;
    line-height: 1.625;
}

.contactPage .contactPage__columnContent h3 {
    font-size: 20px;
    margin-bottom: 7px;
    /*color: var(--color-dark-1);*/
    /*font-size: 18px;*/
    font-weight: 500;
    line-height: 1.3;
}

.contactPage .contactPage__columnContent a:hover {
    color: #fff;
}
.light-content .contact-item {
    color: #FFFFFF !important;
}
.contact-item h3 {
    font-size: 19px;
    font-weight: 900;
}
.contact-item p {
    font-size: 18px;
    line-height: 1.55rem;
}
.contact-item a {
    text-decoration: underline;
}
.contact-item a:hover {
    color: #fff;
}

.google-map.light-content .mt-icon:before {
    background-color: #fff;
    opacity: .15;
}

/* ==========================Blog Page ========================*/
.contentHeader h1 {
    margin-bottom: 20px !important;
}
.blog-item {
    border: 0;
    /*border: 1px solid #707070;*/
}

.blog-item-title:before {
    display: block;
    content: "";
    width: 62px;
    height: 4px;
    background-color: var(--color-orange);
    position: relative;
    margin-bottom: 10px;
}
.blog-item-title--little {
    font-size: 1.4rem;
    padding: 0;
    margin: 0;
}

.highlighted {
    background-color: rgb(158, 71, 57);
}
.total_result {
    font-size: 1.5rem;
    font-style: italic;
}

.widget-posts__img {
    display: inline-block;
    float: left;
}
.widget {
    /*padding-top: 1rem;*/
    /*border-top: 1px solid #CCCCCC;*/
}
.widget:first-child {
    padding-top: 0;
    border-top: 0;
}
.widget-posts-descr {
    display: inline-block;
    width: 60%;
    margin-left: 1rem;
    margin-top: -4px;
}
.widget .widget__title {
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: .6rem;
    font-size: 22px;
}
.blog-item-more {
    font-size: 16px;
}

/* ========================== Content with sparks background ========================*/
.bg-sparks{
    background: url(/static/core/img/bg-sparks.webp) no-repeat center center;
    background-position-y: 0;
    background-color: #090623;
    /*background: url("/static/core/img/bg-sparks.webp") center center no-repeat;*/
    /*width: 100%;*/
    /*height: 100%;*/
}

.bg-sparks input {
    -webkit-text-fill-color: #D8D8D8 !important;
    border-color: #D8D8D8  !important;
}

.bg-sparks .form-tip a {
    color: var(--color-gray-light-1);
}

.bg-gray-transparent-1 {
    background: #f1f1f13d;
}

.bg-sparks .form-tip p {
    margin-bottom: 0;
}

/* ========================== Login Page ========================*/
.loginPage .contentHeader p {
    margin-top: 84px;
    font-size: 19px;
}


/* ========================== Pro Page ========================*/
.proItem {
    height: 363px;
    text-decoration: none;
    position: relative;
    border-radius: 10px;
}

.proItem:hover {
    text-decoration: none;
}

.proItem .services-3-item {
    padding: 0;
    max-width: 410px;
    min-width: 100%;
    border-radius: 10px !important;
    background: rgb(0, 0, 0, .5);
}
.services-3-item:hover {
    box-shadow: var(--box-shadow-block-strong);
    transform: none;
}
.proItem .services-3-text {
    color: var(--color-dark-1);
    padding: 50px 50px 0;
}

.proItem__content {
    display: flex;
    position: absolute;
    color: var(--color-gray-light-1) !important;
    background-color: transparent;
    padding: 15px 10px 10px;
    overflow: hidden;
    top: 0;
    width: calc(100% - 30px);
    height: 100%;
    /*max-width: 410px;*/
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    transition: background-color 1s ease-out;
    -moz-transition: background-color 1s ease-out;
    -webkit-transition: background-color 1s ease-out;
    -o-transition: background-color 1s ease-out;
    border-radius: 10px;
}
.proItem__content:hover {
    background-color: rgba(159, 72, 56, 0);
}
.proItem__contentBottom {
    position: absolute;
    bottom: 40px;
    width: calc(100% - 20px);
}
.proItem__contentBottom .btn {
    border-color: #fff;
    background-color: #00000033;
    color: #FFFFFF;
    width: 70%;
    padding: 7px;
}
.proItem__contentBottom .btn:hover {
    border-color: #fff;
    background-color: #00000033;
    color: #FFFFFF;
}
.proItem .services-3-title {
    font-size: 35px;
    padding: 0 50px;
}


.proItem__content--first {
    background-image: none;
    background-color: transparent !important;
}

.proItem__img {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}


.proItem__logo {
    margin-top: 90px;
}

.download__list {
    margin-bottom: 20px;
}
.download__list--pl0 {
    padding-left: 0;
}
.download__item {
    list-style: none;
    margin-bottom: 20px;
    display: inline-block;
    margin-right: 1rem;
}
.download__item--block {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.download__itemSize  {
    font-size: 11px;
    display: inline-block;
    font-weight: 400;
}

.proDownload__link {
    padding: 5px 10px;
    text-decoration: none;
    border: 1px solid #707070;
    border-radius: 3px;
}


.proPhoto_downloadIco {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 15px;
    display: inline-block;
    position: relative;
    z-index: 0;
    transform: translateY(-35px);
    text-align: center;
    float: right;
    margin: 0 5px;
    width: 30px;
    height: 30px;
}

/* ========================== Event Date ========================*/
.eventDate__year {
    font-size: 23px;
    font-weight: 900;
    color: #CD7160 !important;
    /*color: #9f4838 !important*/
    font-family: var(--font-title);
}
.eventDate__year--emphase {
    font-size: 20px;
    color: #898989;
}
.accordion-button.eventDate__year {
    font-family: var(--font-title)!important;
}

.eventDate__date {
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
}
.eventDate__internalLink, .eventDate__externalLink {
    text-decoration: none;
    color: #fff;
}
.eventDate__internalLink {
    text-transform: uppercase;
    font-weight: 900;
}
.eventDate__internalLink:hover, .eventDate__externalLink:hover {
    color: #fff;
}

eventDate__place {
    color: #fff;
}

/* ========================== Forms ========================*/
.event__headerDescription {
    font-size: 20px;
    display: inline-block;
    margin-right: 1.5rem;
}
.event__headerDescription .event__headerDescription__icon {
    vertical-align: top;
    line-height: 28px;
}
.event__headerDescription:last-child {
    margin-right: 0;
}
/* ========================== Forms ========================*/
.form-control--transparent,
.form-control.form-control--transparent:focus {
    background: transparent;
}
.form select.form-control--transparent,
.form-control.form-control--transparent:focus {
    color: #898989 !important;
    border-color: #CCCCCC;
}

.form select.form-control--transparent:not([multiple]) {
    padding-right: 24px !important;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="9px" height="5px"><path fill-rule="evenodd" fill="rgb(137, 137, 137)" d="M8.886,0.631 L8.336,0.117 C8.263,0.049 8.178,0.015 8.083,0.015 C7.988,0.015 7.903,0.049 7.830,0.117 L4.506,3.155 L1.183,0.117 C1.109,0.049 1.025,0.015 0.930,0.015 C0.834,0.015 0.750,0.049 0.677,0.117 L0.127,0.631 C0.053,0.699 0.017,0.778 0.017,0.867 C0.017,0.956 0.053,1.035 0.127,1.103 L4.253,4.892 C4.327,4.960 4.411,4.994 4.506,4.994 C4.602,4.994 4.686,4.960 4.759,4.892 L8.886,1.103 C8.959,1.035 8.996,0.956 8.996,0.867 C8.996,0.778 8.959,0.699 8.886,0.631 L8.886,0.631 Z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 15px 10px;
}
select option {
    background-color:#180524 !important;
    color: #898989 !important;
}

/* ========================== Accordion ========================*/
.accordion {
    border-bottom: 1px solid var(--color-gray-light);
}

.accordion-item:first-of-type .accordion-button,
.accordion-button, .accordion-button:not(.collapsed) {
    position: relative;
    color: #9F4838 !important;
    font-weight: 900;
    text-decoration: none;
    padding: 20px 5px 20px 0;
    border-top: 1px solid var(--color-gray-light-1);
    transition: var(--transition-default);
    font-family: var(--bs-body-font-family);

}

.accordion-button:after {
    color: var(--color-gray-light-1);
    content: "\e91f";
    display: block;
    font-family: icons !important;
    font-size: 22px;
    height: 1em;
    line-height: 1em;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: var(--transition-default);
    width: 1em;
}
.accordion-button:not(.collapsed):after {
    /*background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);*/
    transform: rotate(-180deg);
}

.accordion-button.eventDate__year--passed,
.accordion>dt>a.eventDate__year--passed {
    color: #898989 !important;
}
.accordion-button:focus {
    border-color: var(--color-gray-light-1);
    box-shadow: none;
}

.accordion-item,
.accordion-button,
.accordion-button:not(.collapsed) {
    background-color: transparent;
}

.eventDate_border {
    border-bottom: 1px solid #707070;
}
.eventDate_border:last-child {
    border-bottom: 0;
    margin-bottom: 40px;
}

.event__passedDate--limited .eventDate_border:last-child {
    border-bottom: 1px solid #707070;
    margin-bottom: 0;
}

.event__passedDate .eventDate__table {
    border-top: 1px solid #707070;
    margin-top: -20px;
}
.event__passedDate,
.event__passedDate .eventDate__date,
.event__passedDate .eventDate__event,
.event__passedDate .eventDate__place,
.event__passedDate .eventDate__placeName,
.event__passedDate .eventDate__externalLink,
.event__passedDate .eventDate__internalLink
{
    color: #898989 !important;
}

.event__passedDateTitle {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
}

/* ========================== Button ========================*/
.btn-dark-border {
    border-color: var(--color-gray-light-1) !important;
}

.light-content .btn-mod.btn-border,
.btn-mod.btn-border.btn-light {
    color: #FFFFFF !important;
    border-color: #FFFFFF !important;
}
.btn-orange {
    border-color: var(--color-orange) !important;
    color: var(--color-orange) !important;
}

.work-title {
    font-size: 22px;
}

.snippet__musicPlayer {
    width: 100%;
    max-width: 700px;
    margin: 1.5rem 0;
}
.snippet__musicPlayer--center {
    margin: 1.5rem auto;
}

.work-navigation a,
.work-navigation div {
    cursor: default;
    display: block;
    float: left;
    overflow: hidden;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 33.33333%;
}
.work-navigation .deactivate {
    visibility: hidden;
}
.work-navigation.light-content span:hover {
    background-color: transparent;
}
/* ========================== Featured Event ========================*/
.featuredEvent__section {
    background: #a84c39 url(/static/core/img/bg-featured-event.webp) no-repeat center top;
    padding-bottom: 90px;
}
.event__headerDescription--lower {
    font-size: 18px;
}
.event__headerTitle--featured {
    font-size: 56px;
}
.event__headerTitle--featured:before,
.title-deco:before {
    content: "";
    position: absolute;
    margin-top: -5px;
    width: 62px;
    height: 4px;
    background-color: #9F4838;
}
.title-deco:before {
    margin-top: -10px;
}

.event__headerLinkAll {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.event__headerLinkAll a, .event__headerLinkAll a:hover {
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, .8);;
}
.event__headerLinkAll a:hover {
    border-color: #FFFFFF;
}

/* CMS */
.cms-render-model-icon, .cms-render-model-icon img {
    background: url(/static/core/img/toolbar/render_model_icon.svg) no-repeat !important;
}


.tpl-minimal-tabs>li>a,
.tpl-minimal-tabs>li>button {
    border-radius: 100px !important;
    padding: 5px 20px;
    text-decoration: none;
}

.light-content .tpl-minimal-tabs>li>a,
.light-content .tpl-minimal-tabs>li>button {
    color: #fff;
}

.light-content .tpl-minimal-tabs li a.active,
.light-content .tpl-minimal-tabs li button.active {
    border: 1px solid #fff !important;
    color: #fff !important;
    background: none !important;
}

.fw-menu li a.selected {
    font-weight: bold;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitronAlertBig #tarteaucitronPersonalize, #tarteaucitronAlertBig #tarteaucitronPersonalize2, .tarteaucitronCTAButton, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
    background: transparent !important;
    border: 1px solid #fff;
}

#tarteaucitronRoot button.tarteaucitronCTAButton, #tarteaucitronRoot button#tarteaucitronCloseAlert, #tarteaucitronRoot button#tarteaucitronPrivacyUrl {
    border: 1px solid #fff !important;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
    color: #FFFFFF !important;
}
#tarteaucitronRoot button#tarteaucitronCloseAlert {
    display: none;
}


@media only screen and (min-width: 640px) {
    .proItem__content {
        background-color: rgba(159, 72, 56, 0.8);
    }
}

div.cms .cms-form-login input[type=password], div.cms .cms-form-login input[type=text] {
    color: var(--dca-gray) !important;
    -webkit-text-fill-color: var(--dca-gray);
}